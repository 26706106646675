<template>
  <v-container  fill-height style="max-width:960px;background-color:rgb(115, 114, 112,0.3)">
    <v-layout justify-center column>
      <v-form ref="entryForm" v-model="valid" @submit.prevent="onRegister">
        <v-container>
          <v-row>
       <v-layout
          justify-center
          align-center column
        >    
     <v-img

style="margin-top:50px; "
  max-width="200"
  src="../assets/bdo_logo_skattedag2022.png"
></v-img>
       </v-layout>
          </v-row>
           <v-row>
 
    <h1 class="white--text" style="">Personligt</h1>
       
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
            
              <v-text-field
                
                label="Förnamn*"
                :rules="[rules.required]"
                v-model="firstname"
                filled
                background-color="grey lighten-2"
                color="black"
              ></v-text-field>
              <v-text-field
                
                :rules="[rules.required]"
                v-model="lastname"
                label="Efternamn*"
                filled
                background-color="grey lighten-2"
                color="black"
              ></v-text-field>

                  <v-text-field
                
                v-model="company"
                 :rules="[rules.required]"
                label="Företag*"
                filled
                background-color="grey lighten-2"
                color="black"
              ></v-text-field>
           
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                :type="tel"
                v-model="phone"
                label="Mobiltelefon*"
                :rules="[rules.required]"
                filled
                background-color="grey lighten-2"
                color="black"
              ></v-text-field>
              <v-text-field
                
                v-model="city"
                label="Stad*"
                :rules="[rules.required]"
                filled
                background-color="grey lighten-2"
                color="black"
              ></v-text-field>

         

              <v-text-field
                
                v-model="contactPerson"
                label="Kontaktperson på BDO"
                filled
                background-color="grey lighten-2"
                color="black"
              ></v-text-field>

                <v-select
                  color="black"
          :items="items"
          label="Kund till BDO*"
          filled
          background-color="grey lighten-2"
          v-model="customerToBdo"
          
          :rules="[rules.required]"
        ></v-select>

          
            </v-col>
          </v-row>

            <v-row>
 
    <h1 class="white--text">Ditt konto</h1>
       
          </v-row>

            <v-row>
            <v-col cols="12" sm="6">
            
             

              <v-text-field
                required
                label="E-postadress*"
                v-model="email"
                :rules="[rules.required, rules.email]"
                :type="username"
                
                background-color="grey lighten-2"
                filled
                color="black"
              ></v-text-field>

              <v-text-field
                label="Lösenord*"
                filled
                v-model="password"
                background-color="grey lighten-2"
                color="white"
                :type="'password'"
              ></v-text-field>
           
            </v-col>

            <v-col cols="12" sm="6">
                 <v-text-field
                label="Bekräfta lösenord*"
                filled
                v-model="passwordRepeated"
                
                background-color="grey lighten-2"
                color="black"
                :type="'password'"
              ></v-text-field>
               <v-checkbox
               
                color="white"
                class="theme--dark"
                 
                 
                v-model="checkboxGDRP"
                :rules="[
                  (v) =>
                    !!v ||
                    'Du måste godkänna integritetspolicy för att fortsätta',
                ]"
                required
              >
                <div slot="label" style="color: white; font-size: 1.1em">
                  Jag har läst och accepterar

                  <a @click="openPolicy" class="white--text text-decoration-underline"> integritetspolicy </a>
                </div>
              </v-checkbox>
            
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
            
                
              
              <v-btn style="margin-bottom:50px" class="white--text" filled color="#df8639" large type="submit"
                >Registrera mig</v-btn
              >
              
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-layout>
    <v-dialog v-model="gdprDialog" width="500" class="">
      <div class="white text-md-left" style="padding: 50px 30px">
        <div class="summary-content">
          <h1></h1>
          <h1>Privacy Policy</h1>

          <p style="outline: none">
            Samtycke till behandling<br />
            Vilka personuppgifter samlar ni in när jag besöker www.ulrika.se?<br />
            Teknisk information<br />
            Vilka personuppgifter samlar ni på www.ulrika.se?<br />
            Hur använder ni mina personuppgifter?<br />
            Dina rättigheter<br />

            senaste ändrad 26 januari 2021
          </p>
        </div>
      </div>
    </v-dialog>
  </v-container>
</template>

<style scoped>
style>
 .v-input__slot::before {
    border-color: grey !important;
  }
.v-input--is-focused .v-input {
  border: 2px solid #005fcc !important;
  border-bottom-color: rgba(0, 0, 0, 0.38) !important;
}
</style>
<script>
// @ is an alias to /src

export default {
  components: {},
  data: () => ({
    username: "",
    gdprDialog: false,
    checkboxGDRP: false,
    valid: false,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 10 || "Name must be less than 10 characters",
    ],
    email: "",
    firstname: "",
    lastname: "",
    kommun: "",
    city: "",
    phone: "",
    password: "",
    company: "",
    contactPerson: "",
    radioGroup: null,
    customerToBdo: null,
    passwordRepeated: "",
    rules: {
      required: (value) => !!value || "Fältet krävs.",
      counter: (value) => value.length <= 20 || "Max 20 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Ingen gilltig email";
      },
    },
    items: ["Ja", "Nej"],
  }),

  mounted() {
      // this.$emit("showAppBar", true);
  },
  methods: {
    openPolicy() {
      window.open(
        "https://www.bdo.se/sv-se/legal-privacy-sv/privacy-policy",
        "_blank"
      );
    },
    async onRegister() {
      if (this.$refs.entryForm.validate()) {
        if (this.password != this.passwordRepeated) {
          alert("Lösenorden matchar inte varandra.");
        }
        if (!this.checkboxGDRP) {
          alert("Du måste godkänna integritetspolicyn innan du kan fortsätta");
        } else {
          console.log("login", this.email, this.password);

          var register = await this.$auth
            .register(this.email, this.password)
            .catch(function (error) {
              // Handle Errors here.
              var errorCode = error.code;
              var errorMessage = error.message;
              // [START_EXCLUDE]
              if (errorCode == "auth/weak-password") {
                alert("Lösenordet är för svagt");
              } else {
                alert(errorMessage);
              }
            });
          if (register) {
            await this.$auth.addUserRecord({
              firstname: this.firstname,
              lastname: this.lastname,
              city: this.city,
              contactPerson: this.contactPerson,
              phone: this.phone,
              email: this.email,
              company: this.company,
              customerToBdo: this.customerToBdo,
            });

            this.$router.replace("meny");
          }
        }
      }
    },
  },
};
</script>
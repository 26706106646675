<template>
  <v-container fluid fill-height>
    <v-layout justify-center column>
      <v-form v-model="valid" @submit.prevent="onSubmit">
        <v-container style="background-color:rgb(115, 114, 112,0.3); padding:0px">
          <v-row>
            <v-col>
              <center><v-img style="margin-top:50px;  height: auto; object-fit:none;" max-width="200"
                  src="../assets/bdo_logo_skattedag2022.png"></v-img></center>



           
              <div>

              <h2 style="color: #000" class=" mt-6  text-xs-center white--text px-10">
                Här lyssnar du till våra erfarna skatterådgivare från hela landet som tar upp aktuella frågeställningar
                inför det kommande beskattningsåret.
              </h2>

              <p style="color: #000;;
            padding: 14px;
            border-radius: 15px;" class="mb-6  text-xs-center white--text">Den bästa uppladdningen får du om du börjar med att
                se introduktionspasset ”Vad händer inom skatteområdet just nu”, du väljer sedan
                fritt bland de olika passen. </p>



              </div>
            </v-col>
          </v-row>
          <staggered-fade :duration="40" tag="div" class="row px-10 py-10">
            <!-- Each element requires a data-index attribute in order for the transition to work properly -->
            <v-col cols="12" sm="6" lg="6" v-for="(item, index) in categories" :data-index="index" :key="index"
              class="menu-btn">
              <v-btn class="" :disabled="item.locked" block elevation="6" @click="navigateToQuestion(item)" large>
                <v-icon left v-if="isFinished(item.id)" color="third">
                  mdi-bookmark
                </v-icon>
                {{ item.name }}
              </v-btn>
            </v-col>
          </staggered-fade>
        </v-container>
      </v-form>
      <v-row>
        <v-col>
          <!-- <p
                 style="color: #fff; padding:10px;"
                class="mb-6 mt-6  text-xs-center"
              >Vi pratar gärna mer - välkommen att kontakta oss</p> -->
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
// @ is an alias to /src

import { db } from "../plugins/FirebaseAuthPlugin";
import StaggeredFade from "../components/StaggeredFade";

import { mapState } from "vuex";
import { intervalToDuration, isFuture } from 'date-fns'

export default {

  computed: {
    ...mapState(["user"]),
  },
  methods: {
    changer: function () {
      this.timer = setInterval(() => {
        this.test = intervalToDuration({
          start: new Date(),
          end: new Date('2023-12-08T10:00:00')
        })

        if (!isFuture(new Date('2023-12-08T10:00:00'))) {
          clearInterval(this.timer);
          this.$router.go();

        }

      }, 1000);
    },
    showMenu() {
      if (this.data == null) {
        return false;
      }
      let showMenu = false;
     
      var date1 = new Date('2023-12-08T10:00:00')
      date1.setHours(8);
      date1.setMinutes(0);

  
      showMenu = false;
      if (this.data.email == "fredrik+2@splitworks.se" || this.data.email == "jenny.jonasson@bdo.se" || this.data.email == "marknad@bdo.se") {
        showMenu = true;
      }


      return showMenu;
    },
    addCategory() {
      this.categories.push({ id: 43243, name: "apa" });
    },
    persist() {
      localStorage.logo = this.logo;
      localStorage.background = this.menuBackground;
    },

    isFinished: function (id) {
      if (this.finishedCourses == null) {
        return false;
      }
      return this.finishedCourses.includes(id);
    },
    navigateToQuestion: function (item) {
      this.$router.push({ name: "questions", params: { id: item.id } });
    },
    getMenu() {
      if (localStorage.logo) {
        this.logo = localStorage.logo;
      }
      if (localStorage.background) {
        this.menuBackground = localStorage.background;
        this.$emit("updateBackground", this.menuBackground);
      } else {
        db.collection("settings")
          .doc("settings")
          .get()
          .then((doc) => {
            this.logo = doc.data().logo;
            this.menuBackground = doc.data().menuBackground;
            this.$emit("updateBackground", this.menuBackground);
            this.persist();
          });
      }

      this.$auth.getUserRecord().then(() => {

        // if (
        //   userData != null &&
        //   (userData.payed == null || userData.payed === false)
        // ) {
        //   this.$router.push("buy");
        // }

        db.collection("categories")
          .orderBy("sortorder")
          .get()
          .then((querySnapshot) => {
            let tmpArray = [];
            querySnapshot.forEach(function (doc) {
              var data = doc.data();
              var icon = data.icon != null ? data.icon : "none";
              tmpArray.push({ id: doc.id, name: data.name, icon: icon });
            });

            //Get bookmarks if user finished any courses
            this.$auth.getUserRecord().then((data) => {
              this.data = data.data()
              let userData = data.data();

              let finishedCategory = [];

              if (userData != null) {
                this.finishedCourses = userData.finishedCourses;
                finishedCategory = userData.finishedCategory;

              }

              if (this.finishedCourses) {

                if (this.finishedCourses.length == tmpArray.length) {
                  // this.diplomDialog = true;
                  this.$auth.updateUserCompleted();
                }
              }

              tmpArray.forEach(function (cat) {
                cat.locked = false;

                if (finishedCategory != null && finishedCategory[cat.id] != null) {
                  cat.finisedTime = finishedCategory[cat.id].time;
                }
              });

              this.categories = tmpArray;
            });

          })
          .then(() => {
            this.isVisible = !this.isVisible;
          });
      });
    },
  },
  setup() {
    this.$tawkMessenger.$on('load', () => {
      this.$tawkMessenger.hideWidget();
    });

  },
  updated() {



  },
  beforeMount() {
    if (isFuture(new Date('2023-12-08T10:00:00'))) {
      this.changer()
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
  ,
  mounted() {


    this.$emit("showAppBar", true);
    this.getMenu();
  },

  components: {
    StaggeredFade
  },
  watch: {
    user() {
      console.log("WATCH USER");
      this.getMenu();
    },
  },
  data: () => ({
    data: null,
    nextNum: 10,
    menuBackground: "",
    logo: "",
    diplomDialog: false,
    finishedCourses: [],
    isVisible: false,
    categories: [],
    valid: false,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 10 || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    items: ["Göteborg", "Uddevalla", "Strömstad", "Skövde"],
    test: null,
    timer: null
  }),
};
</script>

<style>
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #f5f5f5 !important;
}

.menu-btn .v-btn {
  height: 62px !important;
}

.menu-btn .v-btn__content {
  flex: 1;
}

.home {
  font-family: "Trebuchet MS", sans-serif !important;
}

.swbuttonleft {
  width: 380px;
  background-color: #7f7f7f !important;
  height: 76px;
  text-align: left;
  margin: 10px;
  border-radius: 50px !important;
}

.swbuttonright {
  width: 380px;
  background-color: #7f7f7f !important;
  height: 76px;
  text-align: left;
  margin: 10px;
  border-radius: 50px !important;
}

.swbuttonleft .v-btn__content {
  justify-content: start;
}

.swbuttonright .v-btn__content {
  justify-content: end;
}

.swbuttonright .v-btn__content {
  margin-left: 25px;
}

.v-btn .v-icon--left {
  margin-right: 16px;
  margin-left: 23px;
}

.v-btn .v-icon--right {
  margin-right: 30px;
  margin-left: 23px;
}

body {}

.sidebar {
  padding: 20px;
}

.item {
  width: 300px;
  height: 50px;
  border-radius: 5px;
  background: #fff;
  margin-bottom: 10px;
}

.v-btn__content {
  white-space: normal;
  width: inherit;
  word-break: break-word;
}

.staggered-transition {
  transition: all 0.5s ease;
  opacity: 1;
}

.staggered-enter,
.staggered-leave {
  opacity: 0;
}
</style>


